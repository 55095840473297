const _temp0 = require("./avlcrime-min.png");
const _temp1 = require("./climateatlas-min.png");
const _temp2 = require("./eclipse-min.png");
const _temp3 = require("./friendlib-min.png");
const _temp4 = require("./homr-min.png");
const _temp5 = require("./lifetracker-1-min.png");
const _temp6 = require("./lifetracker-2-min.png");
const _temp7 = require("./lifetracker-3-min.png");
const _temp8 = require("./normals-2-min.png");
const _temp9 = require("./paleo-min.png");
const _temp10 = require("./range-min.png");
const _temp11 = require("./robotrace-min.png");
const _temp12 = require("./setlist-min.png");
const _temp13 = require("./transit-min.png");
const _temp14 = require("./webspace-dark-min.png");
const _temp15 = require("./webspace-light-min.png");
module.exports = {
  "avlcrime-min": _temp0,
  "climateatlas-min": _temp1,
  "eclipse-min": _temp2,
  "friendlib-min": _temp3,
  "homr-min": _temp4,
  "lifetracker-1-min": _temp5,
  "lifetracker-2-min": _temp6,
  "lifetracker-3-min": _temp7,
  "normals-2-min": _temp8,
  "paleo-min": _temp9,
  "range-min": _temp10,
  "robotrace-min": _temp11,
  "setlist-min": _temp12,
  "transit-min": _temp13,
  "webspace-dark-min": _temp14,
  "webspace-light-min": _temp15
}